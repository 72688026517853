




























































































































.transaction-history {
  padding: 20px 10px 0px 10px;
  position: relative;
  z-index: 10;
  height: auto;
  .title {
    color: rgba(153, 164, 176, 0.8);
    font-weight: bold;
  }
  .block-section {
    margin-top: 20px;
    @media (max-width: 530px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
    }
    .mx-datepicker {
      width: 37%;
      max-width: 210px;
      margin: 0px 5px;
      @media (max-width: 530px) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
      }
      .mx-input-wrapper {
        input {
          border: 1px solid rgb(45, 48, 53);
          background-color: rgba(84, 86, 88, 0.452);
          border-left: none;
          border-radius: 15px;
          color: rgb(212, 250, 77);
          font-size: 12px;
          &:focus {
            background: none;
            border: 1px solid rgb(123, 197, 20);
            outline: none;
            box-shadow: none;
            color: rgb(212, 250, 77);
          }
        }
        i {
          color: rgba(153, 164, 176, 0.8);
        }
      }
    }
    button {
      height: 100%;
      width: 7.25rem;
      color: #f5f6f7;
      box-shadow: rgb(29 34 37 / 10%) 0px 4px 8px 0px;
      background-color: #5da000;
      background-image: conic-gradient(from 1turn, #458802, #7bc514);
      border-radius: 6.25rem;
      font-weight: bold;
      border: none;
      margin: 0px 5px;
      @media (max-width: 375px) {
        width: 30%;
      }
    }
  }
  table {
    color: white;
    position: relative;
    border-radius: 20px;
    thead {
      border-radius: 10px;
      tr {
        border-radius: 10px;
        th {
          border: none;
          position: sticky;
          top: 0;
          z-index: 10;
          background-color: #5da000;
        }
      }
    }
    tbody {
      border-radius: 10px;
      tr {
        border: none;
        &:nth-child(odd) {
          color: rgb(185, 181, 181);
          background-color: rgba(90, 84, 84, 0.288);
        }
        &:nth-child(even) {
          color: rgb(223, 223, 226);
        }
        td {
          border: none;
        }
      }
    }
  }
  .table-responsive {
    height: auto;
    min-height: 600px;
    max-height: 600px;
    overflow-y: auto;
    border-radius: 10px;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    // &::-webkit-scrollbar-thumb {
    //   background: rgb(60, 255, 0);
    //   border-radius: 10px;
    // }
    .block-section {
      padding-left: 20px;
    }
  }
  .table-hover tbody tr:hover {
    color: rgb(59, 57, 57);
    background-color: rgba(255, 252, 252, 0.89);
  }
}

.table thead > tr > th {
  font-size: 14px;
  font-weight: 600;
}

.pagination {
  display: flex;
  padding-left: 0px;
  margin: 20px 0px;
  border-radius: 4px;
  font-weight: bold;
  li {
    background-color: rgba(90, 84, 84, 0.288);
    border-radius: 8px;
    width: 40px;
    height: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin: 5px;
    &.page-item {
      background: rgba(157, 152, 152, 0.05);
      &.active {
        background: #ffb800;
        color: #2a2e4a;
      }
    }
    &.disabled {
      background-color: rgb(12, 12, 12);
      cursor: not-allowed;
      a {
        cursor: not-allowed;
      }
    }
  }
}
